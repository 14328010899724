.footer {
  margin-top: 50px;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  /* padding-bottom: calc(var(--mantine-spacing-xl) * 2); */
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  border-top: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
}

.logo {
  max-width: 200px;

  @media (max-width: 48em) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.description {
  margin-top: 5px;
  text-align: center;

  @media (max-width: 48em) {
    margin-top: var(--mantine-spacing-xs);
    text-align: center;
  }
}

.inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.link {
  display: block;
  font-size: var(--mantine-font-size-sm);
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.title {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  margin-bottom: 30px;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.afterFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));

  @media (max-width: 48em) {
    flex-direction: column;
  }
}

.social {
  @media (max-width: 48em) {
    margin-top: var(--mantine-spacing-xs);
  }
}